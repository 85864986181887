<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
    </PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row no-gutters>
        <v-col cols="12" md="8" class="pr-2">
            <v-text-field :rules="nameRules" label="Nom"  v-model="item.tty_name"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pr-2">
            <v-select 
                item-text="zon_name" item-value="zon_id" append-outer-icon="mdi-cancel" @click:append-outer="item.tty_zon_id = null"
                :items="itemListZone" v-model="item.tty_zon_id" label="Zone restriciton" >
            </v-select>
        </v-col>
        <v-col cols="12" md="12" class="pr-2">
            <v-text-field label="Description"  v-model="item.tty_description"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pr-2">
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="item.tty_color" block dark class="mt-3" v-on="on"><v-icon left>mdi-palette</v-icon>Couleur</v-btn>
                </template>
                <v-color-picker v-model="item.tty_color" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-col>
        <v-col cols="12" md="6" class="pr-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="stateListByGroup['GEN']" v-model="item.tty_sta_id" label="Statut" >
            </v-select>
        </v-col>
        </v-row>
        </v-form>
        <v-card-actions>
            <v-btn rounded color="error" @click.native="deleteForm()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded text href="/#/tasktypes/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
            <v-btn rounded color="success" class="ml-3" :loading="loading.fetchData" @click.native="saveForm()" :disabled="(loading.fetchData || !validForm)">
                <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
    <v-card fluid class="ma-2 pa-5 elevation-2" v-if="(!loading.fetchData && item.tty_id)">
        <TaskTypePriceList :ttyId="item.tty_id" />
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'

import { mapState, mapWritableState, mapActions } from 'pinia'
import { useTaskTypeStore } from '@/stores/tasktype'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'
import { useZoneStore } from '@/stores/zone'

import TaskTypePriceList from '@/components/tasktypeprices/TaskTypePriceList'

export default {
    name: 'Equipment-Edit',
    props: [ 'contextStyle'],
    mixins: [ ],
    components: { PageHeader, TaskTypePriceList },
    computed: {
        ...mapWritableState(useTaskTypeStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        ...mapState(useZoneStore, {itemListZone: 'itemList' }),
    },
    data: function () {
        return {
            loading: {fetchData: true},         // Enregistrement en cours...
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
        }
    },
    beforeMount: function(){},
    async mounted(){
        if( ( this.$route.params.tty_id ) && this.$route.params.tty_id > 0 ){
            this.loading.fetchData = true
            await this.fetchItem(this.$route.params.tty_id)
            this.loading.fetchData = false
        } else {
            this.initItem()
            this.loading.fetchData = false
        }
    },
    methods: {
        ...mapActions(useTaskTypeStore, { fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem:'destroyItem', initItem: 'RESET_ITEM' }),
        async saveForm () {
            await this.$refs.form.validate()
            if( this.validForm ){
                this.loading.fetchData = true
                if( this.item.tty_id && this.item.tty_id > 0 ){
                    await this.updateItem()
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/tasktypes' })
                } else {
                    await this.storeItem()
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/tasktypes' })
                }
                this.loading.fetchData = false
            }
        },
        async deleteForm(){
            await this.destroyItem()
            this.$root.$toast({ color: 'success', text: 'Fiche supprimée !' })
            this.$router.push({ path: '/tasktypes' })
        },
    }
}
</script>