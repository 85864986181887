<template>
<v-container fluid :class="{ 'blue': dragover }" 
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Liste des fichiers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="enableAdd && $root.$permissions?.Document?.store" class="mr-2"
            outlined small :color="'orange'" @click="openInputFile('DOC04')">
            Photo avant<v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn v-if="enableAdd && $root.$permissions?.Document?.store" class="mr-2"
            outlined small :color="'blue'" @click="openInputFile('DOC05')">
            PHoto après<v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn v-if="enableAdd && $root.$permissions?.Document?.store" 
            outlined rounded small :color="'green'" @click="openDocument(0)">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
        <v-file-input v-show="false" ref="documentfile" id="fileInput" @change="saveNewDocument" v-model="item.obj_file" show-size label="Ajouter fichier / Prendre une photo"></v-file-input>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getDocument"
        loading-text="Chargement... Veuillez patienter"> 
        <template v-slot:[`item.doc_created_at`]="{ item }">
            <v-chip outlined label :color="typeListById[item.doc_typ_id].typ_color">
                    {{ typeListById[item.doc_typ_id].typ_name }}
            </v-chip>
            <v-chip class="ma-1 chip-list" label outlined color="secondary"><v-icon>mdi-clipboard-text-clock-outline</v-icon>&nbsp;{{ $date(item.doc_created_at).format('DD/MM/YYYY') }}</v-chip><br />
        </template>
        <template v-slot:[`item.doc_name`]="{ item }">
            <v-icon v-if="typeListById[item.doc_typ_id].typ_icon">{{ typeListById[item.doc_typ_id].typ_icon }}</v-icon>
            <v-chip class="ma-1" label :color="(itemsCarousel[carouselCurrentIndex] && itemsCarousel[carouselCurrentIndex].doc_id == item.doc_id) ? 'green' : 'secondary'">{{ item.doc_name }}</v-chip>
        </template>
        <template v-slot:[`item.doc_id`]="{ item }">
            <!--<v-icon v-if="( item.doc_mimetype == 'image/jpeg')" left :color="(item.doc_pinned) ? 'red' : 'grey'" title="Associer l'image" @click="pinDocument(item)">
                {{ (item.doc_pinned) ? 'mdi-heart' : 'mdi-heart-outline' }}
            </v-icon>-->
            <v-icon left color='primary' title="Télécharger" @click="downloadDocument(item)">
                mdi-file-download
            </v-icon>
            <v-icon left color='orange' title="Editer" v-if="$root.$permissions?.Document?.update" @click="editDocument(item.doc_id)">
                mdi-pencil
            </v-icon>
            <v-icon left color='red' title="Supprimer" v-if="$root.$permissions?.Document?.destroy" @click="deleteDocument(item.doc_id)">
                mdi-delete-forever
            </v-icon>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getDocument )" color="success" icon="mdi-information-outline">
            Glisser-déposer pour ajouter un fichier ou <a onclick="document.getElementById('fileInput').click()">cliquer ici</a>
        </v-alert>
        </template>
    </v-data-table>
    <div class="elevation-0" style="width:350px; margin-left:auto; margin-right:auto;">
    <v-carousel v-model="carouselCurrentIndex" v-show="( uploadedImage || itemsCarousel.length )" :height="'auto'" ref="resizedImage" class="ml-2 mr-2 elevation-1">
        <v-carousel-item
            v-for="(item,i) in itemsCarousel" :key="i" :src="item.src"
            reverse-transition="fade-transition" transition="fade-transition" @click="downloadDocument(item)"
            style="cursor:pointer;">
            <!--<v-icon left :color="(item.doc_pinned) ? 'red' : 'grey'" title="Associer l'image" @click.stop="pinDocument(item)">
                {{ (item.doc_pinned) ? 'mdi-heart' : 'mdi-heart-outline' }}
            </v-icon>-->
        </v-carousel-item>
    </v-carousel>
    </div>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalDocument" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalDocument', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <DocumentForm class="elevation-2" 
                v-if="modalDocument" :switchModal="switchModal"
                :xxxId="doc_xxx_id" :docId="docInfo.doc_id" :docTable="table_id" :docType="'DOC'" :withFile="uploadedFiles" />
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import DocumentForm from '@/components/documents/DocumentForm.vue'

import { mapState, mapActions, mapWritableState } from 'pinia'
import { useDocumentStore } from '@/stores/document'
import { useTypeStore } from '@/stores/type'

export default {
    name: 'tab-document',
    components: {
        DocumentForm
    },
    props: ['table_id', 'doc_xxx_id', 'routeState'],
    computed: {
        ...mapState(useDocumentStore, { itemList: 'itemList' }),
        ...mapWritableState(useDocumentStore, { item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
    },
    data: function () {
      return {
            loading: { uploadBtn: false, getDocument: false },         // Enregistrement en cours...
            dragover: false,
            uploadedFiles: undefined,
            uploadedImage: false,
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom', align: 'left', value: 'doc_name'},
                { text: 'Date de création', value: 'doc_created_at', align: 'left'},
                { text: 'Télécharger', value: 'doc_id', align: 'right' }
            ],
            enableAdd: true,
            modalLayer : false,
            docInfo: { doc_id: 0, doc_sta_id : 'DOC01', doc_table_id: this.table_id, doc_xxx_id  : this.doc_xxx_id },
            modalDocument: false,
            itemsCarousel: [],
            carouselCurrentIndex: 0
      }
    },
    async mounted() {
        if( this.doc_xxx_id !== undefined ){
            await this.fetchItems('?per_page=false&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id)
            this.loading.getDocument = false
            this.getCarouselImage()
        } else {
            this.setItems( [] )
        }
    },
    methods: {
        ...mapActions(useDocumentStore, { fetchItems: 'fetchItems', downloadItem: 'downloadItem', storeItem: 'storeItem', updateItem: 'updateItem', destroyItem: 'destroyItem', setItems: 'SET_ITEMS', resetItem: 'RESET_ITEM' }),
        openDocument(id){
            this.docInfo.doc_id = id
            this.switchModal('modalDocument', 1)
        },
        editDocument(id){
            this.docInfo.doc_id = id
            this.switchModal('modalDocument', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalLayer', 1)
            }
        },
        async deleteDocument( docid ){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de ce document', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.destroyItem(docid).then(  () => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Document supprimée !' })
                        this.fetchItems('?per_page=false&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id)
                    })
                }
            })
        },
        async downloadDocument(item){
            const report = await this.downloadItem( item.doc_id )
            this.$download(report.blob, item.doc_name, report.contentType)
        },
        pinDocument(obj){
            obj.doc_pinned = (obj.doc_pinned) ? 0 : 1
            this.item = obj
            this.updateItem()
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalDocument'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.uploadedFiles = undefined
                //this.doc_xxx_id = 0
            }
            if( refresh ){
                this.fetchItems('?per_page=false&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id)
                this.getCarouselImage()
            }
        },
        onDrop(e) {
            this.dragover = false;
            if (e.dataTransfer.files.length > 1) {
                this.$root.$toast({ color: 'error', text: 'Un seul fichier à la fois.' })
            } else {
                this.uploadedFiles = e.dataTransfer.files[0] //e.dataTransfer.files.forEach(element => this.uploadedFiles = element )
                this.openDocument(0)
            }
        },
        async getCarouselImage(){
            this.itemsCarousel = []
            for( const pic in this.itemList ){
                if( this.itemList[pic].doc_typ_id == 'DOC04' || this.itemList[pic].doc_typ_id == 'DOC05' ){
                    const img = await this.downloadItem(this.itemList[pic].doc_id, null, '?type=small')
                    if( img.blob && img.blob.size > 0 ){
                        let reader = new FileReader()
                        reader.readAsDataURL(img.blob)
                        reader.onloadend = () => {
                            const base64data = reader.result
                            this.itemList[pic].src = base64data
                            this.itemsCarousel.push( this.itemList[pic] )
                        }
                    }
                    //this.itemsCarousel.unshift( this.itemList[pic] )
                }
            }
        },
        async saveNewDocument(file){
            this.loading.getDocument = true

            let nameTab = file.name.split(".")
            this.item.doc_name = nameTab[0]
            this.item.doc_filename = file.name

            this.item.doc_id       = 0
            //this.item.doc_typ_id   = 'DOC03' //this.types[0].typ_id
            this.item.doc_table_id = this.table_id
            this.item.doc_xxx_id   = this.doc_xxx_id
            this.item.doc_usr_id_created   = 1
            await this.storeItem()
            this.loading.getDocument = false
            await this.fetchItems('?per_page=false&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id)
        },
        openInputFile(typ){
            this.resetItem()
            this.item.doc_typ_id = typ
            this.$refs.documentfile.$refs.input.click()
        }
    }
}
</script>